.Dialog-outer {
  background-color: #0003;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;

}

.Dialog {
  background-color: #fff;
  border-radius: 0.2em;
  height: calc(100% - 2rem);
  left: 1rem;
  overflow: auto;
  position: absolute;
  top: 1rem;
  width: calc(100% - 2rem);
}

.Dialog-item {
  border-color: #ccc;
  border-style: solid none;
  border-width: 1px;
  display: block;
  height: 1em;
  line-height: 1em;
  margin-bottom: -1px;
  padding-bottom: 1em;
  padding-top: 1em;
}

.DialogDangerZone {
  .Dialog-item {
    border-color: tomato;
    box-shadow: 0 0 1em #f003 inset;
  }
}

.DialogTitle {
  font-size: 1.5em;
  height: 2em;
  line-height: 2em;
}

.DialogSection {
  margin-bottom: 1em;
}

.DialogHeading {
  font-size: 1em;
  height: 2em;
  line-height: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.DialogLink {
  background-color: #f9fcff;

  &:active {
    background-color: #f0f9ff;
  }
}

.DialogInput {
  background-color: #f9fcff;
  color: #666;
  height: auto;

  .DialogInputLabel {
    display: block;
    margin-bottom: 0.2em;
  }

  .DialogInnerInput {
    width: 100%;
    height: 2em;
    line-height: 2em;
  }

  .DialogInputDescription {
    font-size: 0.8em;
    font-style: oblique;
    height: 1.2em;
    line-height: 1.2em;
  }
}
