html {
  --color-link: #00f;

  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(800px - 2rem);
  padding: 0 1rem;
}

.antiContainer {
  margin: 0 -1rem;
  width: calc(100% + 2rem);
}

:any-link,
.link {
  color: var(--color-link);
  cursor: pointer;
  text-decoration: underline;
}
